import { IPropChildren } from '@ucheba/utils/helpers/components/prop.types'
import { ELoading } from '@ucheba/store/utils/response/types'
import { ICodeTextFieldProps } from '@ucheba/ui/components/CodeTextField/types'

export interface IApplicationFormProps extends IPropChildren {
  /* Id продукта */
  productId?: number

  /* Тип продукта */
  productType?: EApplicationFormProductTypes

  /* Количество предметов */
  count?: number

  /* Воронка заявки */
  funnel: EApplicationFunnels

  /* Тип заявки */
  requestTarget?: string

  /* ИД предмета */
  subjectId?: string | number

  /* ИД мероприятия */
  eventsId?: string | number

  /* Открытие и закрытие поп-ап заявки */
  open?: boolean

  /* Реферальный код */
  referralCode?: string

  /* Код специального предложения */
  specialOfferCode?: string

  /* событие закрытия заявки */
  close?: () => void

  /* показываем форму в поп-апе? */
  isDialog?: boolean

  /* показывать мессенджеры */
  withMessengers?: boolean

  aboutParent?: boolean

  messengersForm?: boolean

  tgHref?: string

  vkHref?: string

  waHref?: string

  messages?: Array<'vk' | 'tg' | 'wa'>

  agreementText?: string
}

export enum EApplicationFunnels {
  course_booking = 'course_booking',
  free_lesson = 'free_lesson',
  free_test = 'free_test',
  proforientation_test = 'proforientation_test',
  proforientation_request = 'proforientation_request',
  chat_button = 'chat_button',
  select_institution = 'select_institution',
  admission_support = 'admission-support',
  subscription = 'subscription',
  course_program = 'course_program',
  ege_quest = 'ege_quest',
  express_course = 'express_course',
  short_course = 'short_course',
  may_marathon = 'may_marathon',
  summer_intensive = 'may_marathon',
  tutors = 'tutors',
  school_meetings = 'school_meetings',
  program_main = 'program_main',
  institution_main = 'institution_main',
  speciality_main = 'speciality_main',
  speciality_rubrics = 'speciality_rubrics',
  speciality_search = 'speciality_search',
  assessment_of_chances = 'assessment_of_chances',
  ucheba_footer = 'ucheba_footer',
  vuz_rankings = 'vuz_rankings',
  ucheba_profession_lead_form = 'ucheba_profession_lead_form',
  ucheba_search_lead_form = 'ucheba_search_lead_form',
  individual_consult = 'individual_consult',
}

export enum EApplicationFormProductTypes {
  ege = 'ege',
  admissionSupport = 'admission_support',
  oge = 'oge',
  proforientation = 'proforientation',
  proforientationRequest = 'proforientation_request',
  ege_test = 'ege_test',
  select_institution = 'select_institution',
  classroom_hour = 'classroom_hour',
}

export enum EApplicationFormSteps {
  code = 'code',
  parent = 'parent',
  final = 'final',
  messengers = 'messengers',
}

export enum EApplicationFormQuery {
  step = 'step',
  requestId = 'requestId',
  application = 'application',
  productId = 'productId',
  count = 'count',
  subjectId = 'subjectId',
  funnel = 'funnel',
  requestTarget = 'requestTarget',
  eventsId = 'eventsId',
  withPayment = 'withPayment',
  autoReq = 'autoReq',
}

export interface IApplicationFormMessengers extends QuestProps {
  productId?: number
  tgHref?: string
  vkHref?: string
  waHref?: string
  messages?: Array<'vk' | 'tg' | 'wa'>
}

export interface IUseApplicationFormRequestCore {
  (
    props: Pick<
      IApplicationFormProps,
      | 'productId'
      | 'productType'
      | 'count'
      | 'funnel'
      | 'requestTarget'
      | 'subjectId'
      | 'referralCode'
      | 'specialOfferCode'
      | 'aboutParent'
    >
  ): {
    initialValues: Record<string, any>
    onSubmit: (data: Record<string, any>) => void
  }
}

export interface IUseApplicationFormParentCore {
  (): {
    initialValues: Record<string, any>
    onSubmit: (data: Record<string, any>) => void
    isLoading: boolean
  }
}

export interface IUseApplicationFormCodeCore {
  (): {
    phone: string
    requestStep: string
    onInputCode: ICodeTextFieldProps['onChange']
    clientRequestLoading: ELoading
    timeLeft: string
    isTimeLeft: boolean
    getNewCode: () => void
  }
}

export interface IUseApplication {
  (): {
    product: {
      productId: IApplicationFormProps['productId']
      count: IApplicationFormProps['count']
    }
    isApplication: boolean
    funnel?: EApplicationFunnels
    requestTarget: string | null
    subjectsId?: number | string
    eventsId?: number | string
    referralCode?: string
    specialOfferCode?: string
    closeApp(): void
  }
}

export interface IApplicationFormRequestApplicationProps {
  aboutParent?: boolean
  agreementText?: string
}

export enum ERequestTags {
  ege_request_messenger = 'ege_request_messenger',
  ege_request_classic = 'ege_request_classic',
}

export interface QuestProps {
  isEgeQuest?: boolean
}

export interface IUseSelectProduct {
  (): {
    selectGroup: (
      data?: IUseSelectProductData,
      funnel?: EApplicationFunnels,
      typeRequest?: string,
      subjectId?: number | string,
      step?: string,
      eventsId?: number | string,
      shallow?: boolean
    ) => void
    payProduct: (data?: IUseSelectProductData) => void
  }
}

interface IUseSelectProductData {
  productId?: number
  withPayment?: true
  count?: number
  extra?: Record<string, string | number>
}
